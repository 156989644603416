import React from 'react';
import { IonContent, IonPage } from "@ionic/react";
import AllergensModalTrigger from '../components/modals/AllergensModalTrigger';
import features from '../utils/features';
import FiltersLegendModalTrigger from '../components/modals/FiltersLegendModalTrigger';
import Fork from '../components/Fork';
import PoweredBy from '../components/UI/PoweredBy';
import RestaurantLoader from '../components/containers/RestaurantLoader';

function MenuPage(props) {
    const autogrillBackgorund = "autogrill" == process.env.REACT_APP_NAMESPACE && { "--background": "#c43531" }

    return (
        <IonPage>
            <IonContent style={autogrillBackgorund}>
                <section id="menu-page" className="relative font-sans">
                    <RestaurantLoader component={Fork} {...props} />

                    {features.toggler().includeFiltersLegend() && <FiltersLegendModalTrigger />}

                    <AllergensModalTrigger />

                    <PoweredBy />
                </section>
                {features.toggler().includeVersioning() && <p className="text-center text-sm">v.2.5.19</p>}
            </IonContent>
        </IonPage>
    );
}

export default MenuPage;
