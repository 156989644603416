import React from 'react';
import { useEffect } from "react";
import { useParams } from "react-router";
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import formats from '../../utils/formats';

function RestaurantLoader(props) {
    const { component: WrappedComponent } = props;
    const { id } = useParams();
    const { isLoading, restaurant, setRestaurant } = useRestaurant();

    useEffect(() => {
        async function loadRestaurant() {
            await setRestaurant(id);

            if ("morgana" == process.env.REACT_APP_NAMESPACE) {
                if (isLoading == false) {
                    formats.setMorganaMenu(restaurant);
                }
            }
        }

        loadRestaurant();
    }, [id, isLoading]);

    return !isLoading ? (
        <WrappedComponent restaurant={restaurant} {...props} />
    ) : null;
}

export default RestaurantLoader;
