import axios from 'axios';
import formats from '../utils/formats';

export const ENDPOINT = "https://api-ristoratori.mycia.it/googleapis/maps/api/distancematrix/json/?origins=:restaurant_latitude,:restaurant_longitude&destinations=:user_latitude,:user_longitude&mode=driving";
async function doGetDistance(restaurantLat, restaurantLng, userLat, userLng) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "restaurant_latitude": restaurantLat, "restaurant_longitude": restaurantLng, "user_latitude": userLat, "user_longitude": userLng }));
    if (response.data.rows[0] && response.data.rows[0].elements[0] && response.data.rows[0].elements[0].distance && response.data.rows[0].elements[0].distance.value) {
        return response.data.rows[0].elements[0].distance.value;
    } else {
        return null;
    }
}

export default {
    doGetDistance,
}
