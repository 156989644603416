import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from '../utils/contexts/tracker-context';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';

function SplashPage(props) {
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    useLayoutEffect(() => {
        async function track() {
            await trackDataFn(id, "usersession", { "type": type });
        }

        track();
    }, []);

    const autogrillBackground = "autogrill" == process.env.REACT_APP_NAMESPACE && { "--background": "#c43531" }

    return (
        <IonPage>
            <IonContent style={autogrillBackground}>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={Splash} {...props} />
                </section>
                {features.toggler().includeLanguageSelector() && <LanguageSelector onMenu={false} />}
            </IonContent>
        </IonPage>
    );
}

export default SplashPage;
