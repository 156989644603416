import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import NewTabs from './UI/NewTabs';
import Bucket from './cart/Bucket';
import DeliveryPopupModalTrigger from './modals/DeliveryPopupModalTrigger';
import FilterModalTrigger from './modals/FilterModalTrigger';
import LanguageSelector from './LanguageSelector';
import assets from '../utils/assets';
import features from '../utils/features';
import formats from '../utils/formats';
import sharer from '../utils/sharer';
import { shareOutline } from 'ionicons/icons';

function List(props) {
    const { restaurant } = props;

    const { type, id } = useParams();
    const { t } = useTranslation();

    const autogrillBackground = [
        {
            "bg-autogrill-red": "autogrill" == process.env.REACT_APP_NAMESPACE,
        }
    ];

    return (
        <React.Fragment>
            {"mycia" && "autogrill" !== process.env.REACT_APP_NAMESPACE ?
                <div className="w-full p-4">
                    <img src={assets.assetsLogoPath(restaurant.id)} alt="test" />
                </div> :
                <img src={restaurant.image_path} alt={t("menu.img_1")} className="w-full" />
            }

            <div className={`list relative -mt-4 pt-4 pb-4 rounded-t-lg ${clsx(autogrillBackground)}`}>
                <header className="px-4 pb-4 border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-4xl text-white">{t("menu.h1_1")}</h1>
                        </div>
                        {features.toggler().includeLanguageSelector() ? <LanguageSelector onMenu={true} /> :
                            <div>
                                <button onClick={() => (sharer.shareContent(`${type}/q/${id}`, restaurant.name, t("menu.button_1")))} className="button-native w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-autogrill-blue text-white">
                                    <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                                </button>
                            </div>}
                    </div>
                    <p className="mt-4 text-white">{restaurant.menu_description}</p>
                    {formats.shouldDisplayRestaurantCoverCharge(restaurant) ?
                        <p className="mt-4 font-semibold">{t("menu.p_1")}: {formats.formatPrice(restaurant.cover_charge)}</p> : null}

                    {features.toggler().includeFiltersFor() && <FilterModalTrigger />}

                </header>

                <NewTabs />

                <Bucket />

                <DeliveryPopupModalTrigger />
            </div>
        </React.Fragment>
    );
}

export default List;
