import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal } from '@ionic/react';
import AllergensModalContent from './AllergensModalContent';

function AllergensModalTrigger() {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <div className="allergens-modal-trigger my-2 p-4">
            <span onClick={onOpenHandler} className="cursor-pointer block py-1 px-4 rounded-full text-center text-xl bg-autogrill-blue text-white uppercase">{t("allergensmodaltrigger.span_1")}</span>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <AllergensModalContent closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default AllergensModalTrigger;
