import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IonModal } from '@ionic/react';
import DishModalContent from './DishModalContent';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function DishModalTrigger(props) {
    const { dish } = props;

    const { type } = useParams();

    const { restaurant } = useRestaurant();
    const { trackDataFn } = useTracker();
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    async function onOpenHandler() {
        await trackDataFn(restaurant.id, "dishview", { "type": type, "dish": dish.id, "name": dish.name });
        setShowModal(true);
    };

    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <span onClick={onOpenHandler} className="dish-modal-trigger cursor-pointer py-1 underline text-sm text-blue-500">{"autogrill" == process.env.REACT_APP_NAMESPACE ? "Foto e dettagli" : t("dishmodaltrigger.span_1")}</span>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <DishModalContent dish={dish} closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment >
    );
}

export default DishModalTrigger;
