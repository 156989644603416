import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import formats from '../../utils/formats';
import { useTracker } from '../../utils/contexts/tracker-context';
import clsx from 'clsx';

function Splash(props) {
    const { restaurant } = props;
    const { trackDataFn } = useTracker();

    const history = useHistory();
    const { type, id } = useParams();
    const { t } = useTranslation();

    async function handler() {
        await trackDataFn(id, "pageview", { "type": type });
        history.push(formats.formatUrl("/:type/q/:id/menu", { "type": type, "id": id }));
    }

    const classnamesButton = [
        {
            "bg-autogrill-darkblue": "autogrill" == process.env.REACT_APP_NAMESPACE,
            // "bg-mycia-blue": "morgana" != process.env.REACT_APP_NAMESPACE
        }
    ];

    const classnamesTitle = [
        {
            "text-morgana-gold": "morgana" == process.env.REACT_APP_NAMESPACE,
        }
    ];

    return (
        <div className="splash pb-16">
            <img src={restaurant.image_path} alt={t("splash.img_1")} className="w-full mb-12" />

            <div className="py-6 px-4 text-center">
                <h1 className={`mb-8 text-4xl ${clsx(classnamesTitle)}`}>{restaurant.name}</h1>

                <button type="button" onClick={handler} className={`block w-full cursor-pointer py-2 px-8 rounded-md font-medium text-white ${clsx(classnamesButton)}`}>{t("splash.button_1")}</button>
            </div >
        </div >
    );
}

export default Splash;
