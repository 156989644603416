import restaurant from "../logic/restaurant";

function isEven(value) {
    return (Number(value) % 2 == 0) ? true : false;
}

function shouldDisplayCta(type, apiResponse) {
    if (type == "r") {
        return apiResponse.tableservice.active;
    } else if ((type == "d" || type == "t") && (apiResponse.delivery.active == true || apiResponse.takeaway.active == true)) {
        return true;
    }
}

function shouldDisplayDishDetails(dish) {
    return dish ?
        dish.hasOwnProperty("description") && ("" !== dish.description) ||
        dish.hasOwnProperty("ingredients") && (0 < dish.ingredients.length) :
        false;
}

function shouldDisplayDrinkDetails(drink) {
    return drink ?
        drink.hasOwnProperty("description") && ("" !== drink.description) ||
        drink.hasOwnProperty("image_path") && ("" !== drink.image_path) :
        false;
}

function shouldDisplayRestaurantCoverCharge(restaurant) {
    return restaurant ?
        restaurant.hasOwnProperty("cover_charge") && Boolean(restaurant.cover_charge) :
        false;
}

function shouldDisplayPrice(item, field) {
    return item ?
        item.hasOwnProperty(field) && item[field] :
        false;
}

function shouldDisplayPayments(restaurant) {
    let check = restaurant.hasOwnProperty("payments") && restaurant.payments
        .filter(payment => "cash" !== payment.name)
        .filter(payment => payment.active === true);

    return Boolean(check.length);
}

function shouldDisplayPaymentsV2(restaurant, type) {
    if (type == "restaurant") {
        type = "tableservice";
    }

    let check = restaurant.orders[type].hasOwnProperty("payment_methods") && restaurant.orders[type].payment_methods
        .filter(payment => "cash" !== payment.name)

    return Boolean(check.length);
}

function shouldDisplayPaymentsButton(restaurant, name) {
    if (restaurant) {
        if (restaurant.hasOwnProperty("payments")) {
            let check = restaurant.payments.filter(payment => payment.name === name);

            if (check.length > 0) {
                return check[0].active;
            }
        }
    }
    return false;
}

function shouldDisplayPaymentsButtonV2(restaurant, name, type) {
    if (type == "restaurant") {
        type = "tableservice";
    }

    if (restaurant) {
        if (restaurant.orders[type].hasOwnProperty("payment_methods")) {
            let check = restaurant.orders[type].payment_methods.filter(payment => payment.name === name);
            if (check.length > 0) {
                return true;
            }
        }
    }
    return false;
}

function shouldDisplayFilterIcon(element) {
    if (element && element.hasOwnProperty("icon") && element.icon != "" && element.icon != null) {
        return true;
    }
}

function capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function cleanPrice(price) {
    price = Number(price);
    return price && !isNaN(price) ? price : "";
}

function formatPrice(price, locale = "it-IT") {
    let options = { "style": "currency", "currency": "EUR", "minimumFractionDigits": 2 };
    return new Intl.NumberFormat(locale, options).format(price);
}

function formatUrl(url, obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const token = `:${key}`;
            url = String(url).replace(token, obj[key]);
        }
    }
    return url;
}

function formatIngredients(ingredients) {
    let mapped = ingredients.map((ingredient) => {
        let name = ingredient.name;

        name += ingredient.has_allergens ?
            " (" + ingredient.allergens.map(allergen => allergen.name).join(", ") + ")" :
            "";

        return capitalize(name);
    });

    return mapped.join(", ");
}

function formatDrinkName(drink) {
    let name = capitalize(drink.name);

    name += !!drink.cellar ? ` (${drink.cellar})` : "";
    name += !!drink.year ? ` (${drink.year})` : "";

    return name;
}

function formatDescription(item) {
    let originalDescription = item.hasOwnProperty("description") ?
        String(item.description) : "";

    return originalDescription.length > 100 ?
        originalDescription.substr(0, 97) + "..." :
        originalDescription;
}

function getDayOfWeek(Day) {
    const Days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return Days.indexOf(Day);
}

function shiftTime(dt, minutes) {
    return new Date(dt.getTime() + minutes * 60000);
}

function setMorganaMenu(morganaRestaurant) {
    const idxToStart = morganaRestaurant.courses.findIndex(course => course.name == "dessert");
    morganaRestaurant.sushi = [];
    morganaRestaurant.courses.map((course, i) => {
        if (i > idxToStart) {
            morganaRestaurant.sushi.push(course);
        }
    })
    morganaRestaurant.courses = morganaRestaurant.courses.slice(0, idxToStart);
}

export default {
    isEven,
    shouldDisplayCta,
    shouldDisplayDishDetails,
    shouldDisplayDrinkDetails,
    shouldDisplayRestaurantCoverCharge,
    shouldDisplayPrice,
    shouldDisplayPayments,
    shouldDisplayPaymentsV2,
    shouldDisplayPaymentsButton,
    shouldDisplayPaymentsButtonV2,
    shouldDisplayFilterIcon,
    capitalize,
    cleanPrice,
    formatPrice,
    formatUrl,
    formatIngredients,
    formatDrinkName,
    formatDescription,
    getDayOfWeek,
    shiftTime,
    setMorganaMenu,
}
