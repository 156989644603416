import React from 'react';
import { useParams } from 'react-router';
import Cta from '../cart/Cta';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import assets from '../../utils/assets';

function Product(props) {
    const { product, isTastingMenu } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();

    return (
        <div className="product p-2 bg-autogrill-blue">
            {product.special && <div className="text-sm px-3 bg-yellow-500 rounded-t-lg text-center w-1/2">PIATTO CONSIGLIATO</div>}

            <div className="flex flex-col rounded-lg shadow bg-white">
                <div className="flex">
                    <div className="flex-1 py-2 px-4">
                        <p className="flex items-center font-semibold">
                            {formats.capitalize(product.name)}
                            {formats.shouldDisplayFilterIcon(product) ? <img className="inline-block pl-1 w-5 mr-2" src={assets.assetsPath(product.icon)} /> : null}
                        </p>

                        <Details dish={product} />
                    </div>
                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={product} />

                        <p className="mt-2 font-bold">{formats.formatPrice(product.price)}</p>

                        {("autogrill" == process.env.REACT_APP_NAMESPACE && product.image_path != "") ? <img className="w-24 h-24 my-3 border border-grey-300 rounded-lg" src={product.image_path}></img> : null}
                    </div>
                </div>

                {product.suggested_drink != "" && <div className="px-3 py-1 text-sm bg-grey-400">
                    <p><span className="font-bold">Ottimo con: </span>{product.suggested_drink.name}</p>
                </div>}

                {(!isTastingMenu && formats.shouldDisplayCta(type, restaurant.orders)) ? <Cta product={product} /> : null}
            </div>
        </div >
    );
}

export default Product;
