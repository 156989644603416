import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import Beverage from '../list/Beverage';
import Row from '../list/Row';
import Preferred from '../list/Preferred';
import TastingMenu from '../list/TastingMenu';
import { useCart } from '../../utils/contexts/cart-context';

function NewTabs() {
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    let def;
    if ("autogrill" === process.env.REACT_APP_NAMESPACE || "mycia" === process.env.REACT_APP_NAMESPACE || "test" === process.env.REACT_APP_NAMESPACE) {
        if (restaurant.tasting_menu.length == 0) {
            def = 0;
        } else {
            def = restaurant.tasting_menu.length;
        }
    } else {
        def = restaurant.tasting_menu.length + 1;
    }

    if (restaurant.courses.length == 0) {
        def = restaurant.tasting_menu.length + 1;
    }

    const [activeTab, setIsActiveTab] = useState(def);

    const morganaTabs = [
        {
            "active bg-morgana-gold text-white": "morgana" == process.env.REACT_APP_NAMESPACE,
        }
    ];

    return (
        <Tabs defaultIndex={def} onSelect={(i) => setIsActiveTab(i)}>

            <TabList>
                <div className="tabs relative">
                    <div className="bar overflow-x-scroll flex mx-3 mb-2 py-4">
                        {restaurant.tasting_menu.map((t, i) => (
                            <Tab key={i} index={i} label={t.name} translate={false}>
                                <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                    "rounded-full", "border", "border-solid", "border-autogrill-blue", "text-white",
                                    "whitespace-no-wrap", { "active bg-autogrill-darkblue text-white": activeTab == i })}>{t.name}
                                </div>
                            </Tab>
                        ))}
                        <Tab>
                            <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length })}>{t("tab.button_1")}
                            </div>
                        </Tab>
                        {"morgana" == process.env.REACT_APP_NAMESPACE ?
                            <>
                                <Tab>
                                    <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                        "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                        "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length + 1 })}>SUSHI
                                </div>
                                </Tab>
                                <Tab>
                                    <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                        "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                        "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length + 2 })}>{t("tab.button_2")}
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                        "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                        "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length + 3 })}>{t("tab.button_3")}
                                    </div>

                                </Tab>
                            </> :
                            <>
                                <Tab>
                                    <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                        "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                        "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length + 1 })}>{t("tab.button_2")}
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                        "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                                        "whitespace-no-wrap", { "active text-white bg-autogrill-darkblue": activeTab == restaurant.tasting_menu.length + 2 })}>{t("tab.button_3")}
                                    </div>
                                </Tab>
                            </>}
                    </div>
                </div>
            </TabList>

            {/* tabs render here*/}
            {
                restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
                    <TabPanel>
                        {tastingMenu.courses.map((course, i) => (
                            <TastingMenu course={course} index={i} key={i} tastingMenu={tastingMenu} />
                        ))}
                        {tastingMenu.courses.length != 0 && <div className="text-center py-2 px-4 m-4 bg-mycia-green rounded-full">
                            <button type="button" onClick={() => add(tastingMenu, 1)}>Ordina il menu</button>
                        </div>}
                    </TabPanel>
                ))
            }

            <TabPanel>
                {(restaurant.courses && restaurant.courses.length > 0) ?
                    restaurant.courses.map((course, i) => <Row course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
            </TabPanel>
            {"morgana" == process.env.REACT_APP_NAMESPACE &&
                <TabPanel>
                    <div className="sushi">
                        {(restaurant.sushi && restaurant.sushi.length > 0) &&
                            restaurant.sushi.map((sushiCourse, i) => <Row course={sushiCourse} index={i} key={i} />)}
                    </div>
                </TabPanel>}
            <TabPanel>
                <div className="beverages">
                    {(restaurant.beverages && restaurant.beverages.length > 0) &&
                        restaurant.beverages.map((beverage, i) => <Beverage beverage={beverage} index={i} key={i} />)}
                </div>
            </TabPanel>
            <TabPanel>
                <Preferred />
            </TabPanel>
            {/* -------------------- */}

        </Tabs >
    )
}

export default NewTabs;
