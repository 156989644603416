import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

function DishAdditions(props) {
    const { product } = props;

    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (product.use_additions) {
            delete product.use_additions;
        }
    }, [])

    function onChangeHandler(e) {
        if (e.target.checked) {
            if (!product.use_additions) product.use_additions = [];

            product.use_additions.push({
                "id": product.additions[e.target.attributes[1].value].id,
                "name": product.additions[e.target.attributes[1].value].name,
                "price": product.additions[e.target.attributes[1].value].price,
            });
        } else {
            let index;
            product.use_additions.map((addedAddition, i) => {
                if (addedAddition.id == product.additions[e.target.attributes[1].value].id) {
                    index = i;
                }
            })
            product.use_additions.splice(index, 1);
        }

        let val = e.target.attributes[1].value;
        setChanged({ ...changed, [val]: e.target.checked });
    }


    return (
        <div className="dish-additions pt-3">
            <div className="p-3">
                <p className=""> Che aggiunte vuoi al piatto?</p>
                {product.additions.map((addition, i) =>
                    <div key={i} className={"flex items-center mt-4 rounded-lg bg-grey-200 border-solid border-l-8" + clsx({ " border-mycia-green": changed[i] == true, "border-solid border-l-8 border-transparent": !changed[i] })}>
                        <div className="flex-1">
                            <input type="checkbox" additionindex={i} id={`additions${i}`} name={`additions${i}`} className="hidden" onChange={onChangeHandler} />

                            <label className={clsx(["cursor-pointer select-none block p-4", { "font-bold": changed[i] }])} htmlFor={`additions${i}`}>{addition.name}</label>

                        </div>
                        {(addition.price != 0) && <label className="py-4 px-3" htmlFor="additions">€ {addition.price}</label>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default DishAdditions;
