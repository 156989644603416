import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next'; import { IonPage, IonContent, IonIcon } from '@ionic/react';
import FormTable from '../components/cart/FormTable';
import { useCart } from '../utils/contexts/cart-context';
import { chevronBackOutline } from 'ionicons/icons';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import forms from '../utils/forms';
import formats from '../utils/formats';

function TablesPage() {
    let storage = {};

    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const { number, products, quantity, table, setTable, total, customers, payed } = useCart();
    const { restaurant } = useRestaurant();

    const [terms, setTerms] = useState(false);
    const [checkTables, setCheckTables] = useState(false);
    const [form, setForm] = useState({ "number": "" });

    const buttonDisabled = "opacity-50 + cursor-not-allowed";

    const onClickHandler = () => {
        if (form.number) {
            setTable(form.number);

            let localTotal = total + (restaurant.cover_charge * customers);
            let amount = localTotal * 100;
            let commissions = (localTotal * 0.02) * 100;

            storage["restaurant"] = restaurant;
            storage["order"] = {
                "amount": amount,
                "commissions": commissions,
                "customers": customers,
                "number": number,
                "payed": payed,
                "products": products,
                "quantity": quantity,
                "table": form.number,
                "timestamp": Date.now(),
                "total": localTotal,
                "type": "restaurant",
            };
            storage["transaction"] = {
                "id": forms.generateRandomID("cash_"),
                "provider": "cash",
            }

            window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));

            history.push(`/${type}/q/${id}/orders/success/?provider=cash`);
        }
    };
    const onCheckoutHandler = () => {
        if (form.number) {
            setTable(form.number);

            history.push(`/${type}/q/${id}/orders/payments`);
        }
    };

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders`);

    function handleTerms() {
        setTerms(terms => !terms);
    };

    return (
        <IonPage>
            <IonContent>
                <section id="tables-page" className="relative pt-6 font-sans">
                    <div className="flex items-center border-b border-b-grey-200">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("tablespage.h2_1")}</h2>
                    </div>

                    <div className="px-6">

                        <FormTable setForm={setForm} form={form} checkTables={checkTables} setCheckTables={setCheckTables} />

                        {formats.shouldDisplayRestaurantCoverCharge(restaurant) ?
                            <div className="pt-6 text-center">
                                <p>Coperto {restaurant.cover_charge} € a persona.</p>

                                <p className="float-right pt-3 font-bold">Totale aggiornato: {total + (restaurant.cover_charge * customers)} €</p>
                            </div>
                            : null}
                    </div>


                    <div className="w-full flex flex-col justify-center px-4 py-2 bg-white">
                        <div className="text-xs text-center py-4"> <input type="checkbox" id="terms" name="terms" onChange={handleTerms} />
                            <label className="pl-2">{t("formtable.lable_1.span_1")} <a className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/">{t("formtable.lable_1.a_1")}</a> {t("formtable.lable_1.span_2")}</label>
                        </div>
                        {formats.shouldDisplayPaymentsV2(restaurant, "restaurant") ? <button type="submit" form="table-form" disabled={!(terms && checkTables)} onClick={onCheckoutHandler} className={`block py-2 rounded-full bg-mycia-green ${(terms && checkTables) ? "" : buttonDisabled}`}>{t("tablespage.button_1")}</button> : null}
                        {formats.shouldDisplayPaymentsButtonV2(restaurant, "cash", "restaurant") ? <button type="submit" form="table-form" disabled={!(terms && checkTables)} onClick={onClickHandler} className={`block mt-4 py-2 rounded-full bg-mycia-green ${(terms && checkTables) ? "" : buttonDisabled}`}>{t("tablespage.button_2")}</button> : null}
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default TablesPage;
